import React, { useCallback, useEffect, useState } from "react";

import { NavStep } from "../../fieldComponants/NavStep";
import { Field } from "../../types";

import { useAppSelector } from "../../../../store/rtctype";
import { Step } from "../../types";
import SopHeader from "../../StepForms/sopHeader";
import { ScanField } from "../ScanField";
import InputField from "../InputField";
import SelectField from "../SelectField";
import FileField from "../FileField";
import FileReadField from "../FileReadField";
import { fieldmap, stepSop } from "../../fieldmap";
import StatusField from "../StatusField";
import CheckInput from "../CheckInput";
import {
  getQisId,
  getSignUrl,
  qisModuleFour,
  qisModuleInspection,
  qisModuleOne,
  qisModulePackaging,
  qisModuleThree,
  qisModuleTwo,
} from "../../../../service/apiService";
import { ValidationModel } from "../../../../components/Model/ValidationModel";
import QISInput from "../QISInput";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  handleFieldData,
  resetStepData,
  resetToggle,
} from "../../../../reducers/inspectionReducer";
import ExcelUpload from "../ExcelUpload";
import { QIS4eligibleModel } from "../../../../components/Model/QIS4eligibleModel";
import { FormDetails } from "../../formDetails/FormDetails";
import { handleImageLoading } from "../../../../reducers/authReducer";
import { QISwarning } from "../../../../components/Model/QIS warning";

type Step27Data = {
  cyclePack: boolean | string;
  confirmedFreezerTestConducted: boolean | string;
  confirmedHeatingElementActivated: boolean | string;
  QIS: string;
  uploadDataFromCycler: string;
  packQcCapacityVoltageCanComm: string;
};

const module = [
  "voltageDifferenceOne",
  "voltageDifferenceTwo",
  "voltageDifferenceThree",
  "voltageDifferenceFour",
  "heatShrinkOnTerminalStuds",

  // "removeTTPCover",
  // "voltage",
  // "voltageDifferenceOne",
  // "voltageDifferenceTwo",
  // "voltageDifferenceThree",
  // "voltageDifferenceFour",
];
const QIS1 = [
  "applyHeating",
  "fastenModulesWithTorque",
  "threadZiptiesTopMountingPlate",
  "mountActiveEqualizerAndParallelModule",
  "applyQR",
  "mountBmsWithTape",
  "mountHeaterModule",
  "torqueDownHeaterModule",
  "wiringHeaterModule",
  "rotatePackPlaceTerminalProtection",
  "voltageDifference",
  "connectParallelModuleAndBms",
  "positionFiveCable",

  // "fastenModulesWithTorque",
  // "applyHeating",
  // "threadZiptiesTopMountingPlate",
  // "secureTopMountingPlate",
  // "mountActiveEqualizerAndParallelModule",
  // "mountBmsWithTape",
  // "mountHeaterModule",
  // "torqueDownHeaterModule",
  // "wiringHeaterModule",
  // "applyQR",
  // "rotatePackPlaceTerminalProtection",
  // "placeBusBars",
  // "fastenYHarnessAndWiresWithTorque",
  // "rotatePackAndFastenWiresWithZiptie",
  // "voltageDifference",
  // "installTerminalProtectionCap",
  // "fastenHarnessWiresWithZipties",
  // "connectParallelModuleAndBms",
];
const QIS2 = [
  "tapeBmsThermistorToPack",
  "connectEqualizerAndBmsToYHarness",
  "confirmVoltageTerminalToTerminal",
  "configureBms",
  "confirmVoltageWireToWire",
  "configureActiveEqualizer",

  // "connectEqualizerAndBmsToYHarness",
  // "tapeBmsThermistorToPack",
  // "confirmVoltageTerminalToTerminal",
  // "confirmVoltageWireToWire",
  // "configureBms",
  // "configureActiveEqualizer",
];
const QIS3 = [
  "placeCableMounts",
  "lowerPackIntoEnclosure",
  "confirmLidGasketMounted",
  "fastenWiresWithZipties",
  "secureLidOnEnclosure1",
  "ipqcWireConnectionsLugAndCan485",
  "connectCan485WiresToBms",
  "ipqcModuleConnectionsZiptiesQrCode",
  "securePackToEnclosure",
  "applyQrCodeToTopMountingPlate",

  // "placeCableMounts",
  // "fastenWiresWithZipties",
  // "applyQrCodeToTopMountingPlate",
  // "ipqcModuleConnectionsZiptiesQrCode",
  // "lowerPackIntoEnclosure",
  // "securePackToEnclosure",
  // "fastenWiresToLugsOnLid",
  // "connectCan485WiresToBms",
  // "confirmLidGasketMounted",
  // "ipqcWireConnectionsLugAndCan485",
  // "secureLidOnEnclosure1",
];
const QIS4 = [
  "cyclePack",
  "confirmedFreezerTestConducted",
  "packQcCapacityVoltageCanComm",
  "uploadDataFromCycler",
];
const pack = [
  "applyManufacturedDateLabel",
  "wipeDownEnclosure",
  "attachRedAndBlackLugCovers",
  "photoStationTop",
  "photoStationLeft",
  "photoStationRight",
  "tapeBoxAndLabel",

  // "applySecondQrCodeOnLid",
  // "applyWarningLabel",
  // "applyManufacturedDateLabel",
  // "attachRedAndBlackLugCovers",
  // "wipeDownEnclosure",
  // "photoStationTop",
  // "photoStationLeft",
  // "photoStationRight",
  // "lowerPackIntoBoxWithFoam",
  // "tapeBoxAndLabel",
  // "addHazardousMaterialLabeling",
];

export default function QisForm({ step }: { step: string }) {
  const formData = useAppSelector((state: any) => state.inspection).formData;
  const dispatch = useDispatch();
  const [currentActive, setCurrentActive] = useState<number>(0);
  const [currentActiveModule, setCurrentActiveModule] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [eropen, setErOpen] = useState<boolean>(false);
  const [eliopen, setEliOpen] = useState<boolean>(false);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);
  const [qis4Data, setQis4Data] = useState<string[]>([]);
  const [qisId, setQisId] = useState<string>("");

  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const objKeys = Object.keys(currentFormData);

    const constCount = objKeys.reduce((count, key) => {
      if (currentFormData[key]) {
        return count + (key === "voltageDifferenceFour" ? 2 : 1);
      }
      return count;
    }, 0);

    setCurrentActive(constCount);
  }, [formData[step]]);

  const errorHandle = (errors: any) => {
    const new_err = [];
    new_err.push(errors?.message);
    return new_err;
  };
  // 120224-001-DTX-001

  const setData = (completedQis: number, data: any) => {
    if (completedQis >= currentActiveModule && step === "step_23") {
      setIsView(true);
      module.forEach((obj) => {
        dispatch(
          handleFieldData({
            step: step,
            value: data[obj],
            key: obj,
          })
        );
      });
    } else if (completedQis >= currentActiveModule && step === "step_24") {
      setIsView(true);
      QIS1.forEach((obj) => {
        dispatch(
          handleFieldData({
            step: step,
            value: data[obj],
            key: obj,
          })
        );
      });
    } else if (completedQis >= currentActiveModule && step === "step_25") {
      setIsView(true);
      QIS2.forEach((obj) => {
        dispatch(
          handleFieldData({
            step: step,
            value: data[obj],
            key: obj,
          })
        );
      });
    } else if (completedQis >= currentActiveModule && step === "step_26") {
      setIsView(true);
      QIS3.forEach((obj) => {
        dispatch(
          handleFieldData({
            step: step,
            value: data[obj],
            key: obj,
          })
        );
      });
    } else if (completedQis >= currentActiveModule && step === "step_27") {
      setIsView(false);
      dispatch(
        handleFieldData({
          step: step,
          value: data?.packId,
          key: "QIS",
        })
      );
      QIS4.forEach((obj) => {
        if (obj === "uploadDataFromCycler") {
          return;
        } else {
          dispatch(
            handleFieldData({
              step: step,
              value: data[obj],
              key: obj,
            })
          );
        }
      });

      setCurrentActive(4);
    } else if (completedQis >= currentActiveModule && step === "step_28") {
      setIsView(true);
      pack.forEach((obj) => {
        dispatch(
          handleFieldData({
            step: step,
            value: data[obj],
            key: obj,
          })
        );
      });
    }
  };
  useEffect(() => {
    if (!qisId) return;

    const moduleMapping: any = {
      step_23: 0,
      step_24: 1,
      step_25: 2,
      step_26: 3,
      step_27: 4,
      step_28: 5,
    };

    const messages = [
      "This packid completed Module Inspection",
      "This packid completed QIS 1 : Assembly 1",
      "This packid completed QIS 2 : Calibration",
      "This packid completed QIS 3 : Assembly 2",
      "This packid completed QIS 4 : Cycling",
      "This packid completed Pack Packaging",
    ];

    const remainingMessages = [
      "QIS 1 : Assembly 1 is Remaining",
      "QIS 2 : Calibration is Remaining",
      "QIS 3 : Assembly 2 is Remaining",
      "QIS 4 : Cycling is Remaining",
    ];

    setCurrentActiveModule(moduleMapping[step] || 0);
    setLoadingGet(true);
    setIsView(false);

    getQisId(qisId?.trim())
      ?.then((res: any) => {
        if (!res?.success) {
          if (step === "step_23") {
            const currentDataObj = { ...formData[step] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];
              delete currentDataObj[key];
            }
            dispatch(resetStepData({ step: step, value: currentDataObj }));
            setCurrentActive(1);
            dispatch(
              handleFieldData({
                step: step,
                value: qisId,
                key: "QIS",
              })
            );
          } else {
            console.log("%c Line:315 🥒 res", "color:#4fff4B", res);

            setCurrentActive(0);
            setParamError(
              errorHandle({ message: "Module inspection is remaining" })
            );
            const currentDataObj = { ...formData[step] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];
              delete currentDataObj[key];
            }
            dispatch(resetStepData({ step: step, value: currentDataObj }));
            setErOpen(true);
            setQisId("");
            dispatch(
              handleFieldData({
                step: step,
                value: null,
                key: "QIS",
              })
            );
          }

          // dispatch(resetToggle(!formData.toggle));
        } else {
          const completedQis = res?.data?.data?.completedQis || 0;
          if (moduleMapping[step] === completedQis) {
            toast.success(messages[completedQis]);
            setCurrentActive(0);
            dispatch(
              handleFieldData({
                step: step,
                value: null,
                key: "QIS",
              })
            );

            setData(completedQis, res?.data?.data);

            setQisId("");
          } else if (moduleMapping[step] === completedQis + 1) {
            setCurrentActive(1);
          } else if (moduleMapping[step] > completedQis) {
            const remaining = remainingMessages?.slice(completedQis);
            const remainingNew = remainingMessages?.slice(
              completedQis,
              (moduleMapping[step] || 0) - 1
            );

            const allRemainingMessages =
              remainingNew?.length > 0
                ? remainingNew?.map((item) => item.slice(0, 5)).join(", ")
                : "";
            setParamError(
              errorHandle({ message: `${allRemainingMessages} is remaining` })
            );
            setErOpen(true);
            // toast.error(`${allRemainingMessages} is remaining`);
            dispatch(handleFieldData({ step: step, value: null, key: "QIS" }));
            setQisId("");
            dispatch(
              handleFieldData({
                step: step,
                value: null,
                key: "QIS",
              })
            );
            setQisId("");
          } else if (moduleMapping[step] < completedQis) {
            setData(completedQis, res?.data?.data);

            toast.success(messages[moduleMapping[step]] || "Unknown error");
            dispatch(
              handleFieldData({
                step: step,
                value: null,
                key: "QIS",
              })
            );
            setQisId("");
          }
          setQisId("");
        }
      })
      .catch((error) => {
        console.error("Error fetching QIS ID:", error);
      })
      .finally(() => {
        setLoadingGet(false);
      });
  }, [qisId, dispatch]);

  const handleSubmit = () => {
    if (step === "step_23") {
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS?.trim(),
      };
      delete updateObj?.QIS;
      setLoading(true);
      qisModuleInspection(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setLoading(false);
        setQisId("");
      });
    }
    if (step === "step_24") {
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS?.trim(),
      };
      delete updateObj?.QIS;
      setLoading(true);
      qisModuleOne(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setQisId("");
        setLoading(false);
      });
    }
    if (step === "step_25") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS?.trim(),
      };
      delete updateObj?.QIS;
      qisModuleTwo(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setQisId("");
        setLoading(false);
      });
    }
    if (step === "step_26") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS?.trim(),
      };
      delete updateObj?.QIS;
      qisModuleThree(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setQisId("");
        setLoading(false);
      });
    }
    if (step === "step_27") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS?.trim(),
      };
      delete updateObj?.QIS;
      qisModuleFour(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
            setEliOpen(true);
            setQis4Data(res?.data?.data);
          } else {
            toast?.error(res?.data?.message);
          }
          const currentDataObj = { ...formData["step_27"] };
          const objectKeys = Object.keys(currentDataObj);
          for (let i = 0; i < objectKeys.length; i++) {
            const key = objectKeys[i];
            delete currentDataObj[key];
          }

          dispatch(resetStepData({ step: step, value: currentDataObj }));
          dispatch(resetToggle(!formData.toggle));
        }
        setQisId("");
        setLoading(false);
      });
    }
    if (step === "step_28") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS?.trim(),
      };
      delete updateObj?.QIS;
      qisModulePackaging(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setQisId("");
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (step === "step_23") {
      if (
        formData["step_23"]?.voltageDifferenceOne &&
        formData["step_23"]?.voltageDifferenceTwo &&
        formData["step_23"]?.voltageDifferenceThree &&
        formData["step_23"]?.voltageDifferenceFour
      ) {
        const max = Math.max(
          formData["step_23"]?.voltageDifferenceOne,
          formData["step_23"]?.voltageDifferenceTwo,
          formData["step_23"]?.voltageDifferenceThree,
          formData["step_23"]?.voltageDifferenceFour
        );
        const min = Math.min(
          formData["step_23"]?.voltageDifferenceOne,
          formData["step_23"]?.voltageDifferenceTwo,
          formData["step_23"]?.voltageDifferenceThree,
          formData["step_23"]?.voltageDifferenceFour
        );
        const total = (max - min) / 2 >= 0.45;
        if (total) {
          setWarningOpen(true);
          setHasNext(false);
        } else {
          setWarningOpen(false);
          setHasNext(true);
        }
      }
    }
  }, [
    formData["step_23"]?.voltageDifferenceOne,
    formData["step_23"]?.voltageDifferenceTwo,
    formData["step_23"]?.voltageDifferenceThree,
    formData["step_23"]?.voltageDifferenceFour,
  ]);

  useEffect(() => {
    if (step === "step_24") {
      if (formData["step_24"]?.voltageDifference) {
        const total = formData["step_24"]?.voltageDifference >= 0.45;

        if (total) {
          setWarningOpen(true);
          setHasNext(false);
        } else {
          setWarningOpen(false);
          setHasNext(true);
        }
      }
    }
  }, [formData["step_24"]?.voltageDifference]);

  useEffect(() => {
    if (step === "step_24" || step === "step_23") {
      setHasNext(false);
    } else {
      setHasNext(true);
    }
  }, [step]);

  useEffect(() => {
    // if (step === "step_27") {
    //   if (
    //     formData["step_27"]?.uploadDataFromCycler &&
    //     formData.step_27?.uploadDataFromCycler instanceof File
    //   ) {
    //     const data: any = formData["step_27"];
    //     const vFiles = data?.uploadDataFromCycler;
    //     setLoading(true);
    //     getSignUrl({
    //       fileName: data?.uploadDataFromCycler?.name,
    //       his: "QIS-4",
    //     }).then((res: any) => {
    //       if (res?.success && res?.data && res?.data?.data) {
    //         const xhr = new XMLHttpRequest();
    //         xhr.open("PUT", res?.data?.data?.signUrls, true);
    //         xhr.setRequestHeader("Content-Type", vFiles?.type);

    //         xhr.onreadystatechange = () => {
    //           if (
    //             xhr.readyState === XMLHttpRequest.DONE &&
    //             xhr.status === 200
    //           ) {
    //             setLoading(false);
    //             dispatch(
    //               handleFieldData({
    //                 step: "step_27",
    //                 value: res?.data?.data?.url,
    //                 key: "uploadDataFromCycler",
    //               })
    //             );
    //           }
    //         };

    //         xhr.send(vFiles);
    //       }
    //     });
    //   }
    // }
    if (step === "step_28") {
      if (
        formData["step_28"]?.photoStationTop &&
        formData.step_28?.photoStationTop instanceof File
      ) {
        if (formData["step_28"]?.photoStationTop.type.startsWith("image/")) {
          const data: any = formData["step_28"];
          const vFiles = data?.photoStationTop;
          getSignUrl({
            fileName: data?.photoStationTop?.name,
            his: "QIS-P",
          }).then((res: any) => {
            if (res?.success && res?.data && res?.data?.data) {
              const xhr = new XMLHttpRequest();
              xhr.open("PUT", res?.data?.data?.signUrls, true);
              xhr.setRequestHeader("Content-Type", vFiles?.type);
              xhr.onload = () => {
                dispatch(handleImageLoading(true));
              };
              xhr.onreadystatechange = () => {
                if (
                  xhr.readyState === XMLHttpRequest.DONE &&
                  xhr.status === 200
                ) {
                  dispatch(
                    handleFieldData({
                      step: "step_28",
                      value: res?.data?.data?.url,
                      key: "photoStationTop",
                    })
                  );
                }
              };

              xhr.send(vFiles);
            }
          });
        }
      }
    }
  }, [formData["step_28"]?.photoStationTop]);

  useEffect(() => {
    if (
      formData["step_28"]?.photoStationLeft &&
      formData.step_28?.photoStationLeft instanceof File
    ) {
      if (formData["step_28"]?.photoStationLeft.type.startsWith("image/")) {
        const data: any = formData["step_28"];
        const vFiles = data?.photoStationLeft;
        getSignUrl({
          fileName: data?.photoStationLeft?.name,
          his: "QIS-P",
        }).then((res: any) => {
          if (res?.success && res?.data && res?.data?.data) {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", res?.data?.data?.signUrls, true);
            xhr.setRequestHeader("Content-Type", vFiles?.type);
            xhr.onload = () => {
              dispatch(handleImageLoading(true));
            };
            xhr.onreadystatechange = () => {
              if (
                xhr.readyState === XMLHttpRequest.DONE &&
                xhr.status === 200
              ) {
                dispatch(
                  handleFieldData({
                    step: "step_28",
                    value: res?.data?.data?.url,
                    key: "photoStationLeft",
                  })
                );
              }
            };

            xhr.send(vFiles);
          }
        });
      }
    }
  }, [formData["step_28"]?.photoStationLeft]);

  useEffect(() => {
    if (
      formData["step_28"]?.photoStationRight &&
      formData.step_28?.photoStationRight instanceof File
    ) {
      if (formData["step_28"]?.photoStationRight.type.startsWith("image/")) {
        const data: any = formData["step_28"];
        const vFiles = data?.photoStationRight;
        getSignUrl({
          fileName: data?.photoStationRight?.name,
          his: "QIS-P",
        }).then((res: any) => {
          if (res?.success && res?.data && res?.data?.data) {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", res?.data?.data?.signUrls, true);
            xhr.setRequestHeader("Content-Type", vFiles?.type);
            xhr.onload = () => {
              dispatch(handleImageLoading(true));
            };
            xhr.onreadystatechange = () => {
              if (
                xhr.readyState === XMLHttpRequest.DONE &&
                xhr.status === 200
              ) {
                dispatch(
                  handleFieldData({
                    step: "step_28",
                    value: res?.data?.data?.url,
                    key: "photoStationRight",
                  })
                );
              }
            };

            xhr.send(vFiles);
          }
        });
      }
    }
  }, [formData["step_28"]?.photoStationRight]);

  const handleErrorClose = useCallback(() => {
    setErOpen(false);
    setParamError([]);
  }, [paramError, setParamError]);

  const handleValidationClose = useCallback(() => {
    setEliOpen(false);
    setQis4Data([]);
  }, [qis4Data, setQis4Data]);

  // 111824-001-DTX-001
  // 112524-002-HTX-001
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner">
        <div className="sm-stepForm-banner">
          <SopHeader title={fieldmap[step]?.title} link={""} />
          <FormDetails step={step} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box">
              {fieldmap[step] &&
                fieldmap[step]?.fields.map((field: Field, index: number) => {
                  if (field.type === "field") {
                    return (
                      <InputField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                        isView={isView}
                      />
                    );
                  } else if (field.type === "fieldQIS") {
                    return (
                      <QISInput
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        loading={loadingGet}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                        setQisId={setQisId}
                        // handleClick={handleGetData}
                      />
                    );
                  } else if (field.type === "checkbox") {
                    return (
                      <CheckInput
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        loading={loading}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                        isView={isView}
                      />
                    );
                  } else if (field.type === "select") {
                    return (
                      <SelectField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                        loading={loadingGet}
                      />
                    );
                  } else if (field.type === "excel") {
                    return (
                      <ExcelUpload
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "file") {
                    return (
                      <FileField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        loading={loadingGet}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "reset") {
                    return (
                      <NavStep
                        step={step}
                        data={field}
                        loading={loading}
                        status={index <= currentActive}
                        key={field.key}
                        onClick={handleSubmit}
                        isView={isView}
                        hasNext={hasNext}
                      />
                    );
                  }

                  return null;
                })}
            </div>
          </div>
        </div>
      </div>

      <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
        step={step}
      />
      <QIS4eligibleModel
        open={eliopen}
        setOpen={handleValidationClose}
        validation={qis4Data}
      />
      <QISwarning open={warningOpen} setOpen={setWarningOpen} />
    </React.Fragment>
  );
}
