import React, { useEffect, useState, useCallback } from "react";
import { fieldmap, stepSop } from "../fieldmap";
import InputField from "../fieldComponants/InputField";
import { ScanField } from "../fieldComponants/ScanField";
import FileField from "../fieldComponants/FileField";
import StatusField from "../fieldComponants/StatusField";
import { NavStep } from "../fieldComponants/NavStep";
import { Field } from "../types";
import { FormDetails } from "../formDetails/FormDetails";
import SelectField from "../fieldComponants/SelectField";
import CustomAction from "../fieldComponants/CustomAction";
import { useAppSelector } from "../../../store/rtctype";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  SABProcessService,
  createBinService,
  createElectricalInspectionFive,
  createElectricalInspectionFour,
  createElectricalInspectionOne,
  createElectricalInspectionSix,
  createElectricalInspectionThree,
  createElectricalInspectionTwo,
  createModulePackagingService,
  handleCreateLot,
  handleCreatePallet,
} from "../serviceCallback";
import { ValidationModel } from "../../../components/Model/ValidationModel";
import FileReadField from "../fieldComponants/FileReadField";
import SopHeader from "./sopHeader";
import moment from "moment";
import HISInput from "../fieldComponants/HISInput";
import Label from "../fieldComponants/Label";
import {
  getLotService,
  getModuleById,
  getOEMModelData,
  getPallet,
  getSignUrl,
} from "../../../service/apiService";
import { useDispatch } from "react-redux";
import {
  handleFieldData,
  handleModule,
  resetStepData,
  resetToggle,
} from "../../../reducers/inspectionReducer";
import ExcelUpload from "../fieldComponants/ExcelUpload";
import { handleImageLoading } from "../../../reducers/authReducer";

export default function InspectionForm({ step }: { step: string }) {
  const dispatch = useDispatch();
  const formData = useAppSelector((state: any) => state.inspection).formData;
  //
  const moduleData = useAppSelector((state: any) => state.inspection).module;
  //
  const userData = useAppSelector((state) => state.user).user;
  const { imgLoading } = useAppSelector((state) => state.user);
  const [currentActive, setCurrentActive] = useState<number>(0);
  const [module, setModule] = useState<number>(0);
  //
  const [eropen, setErOpen] = useState<boolean>(false);
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [loadingSign, setLoadingSign] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);
  const [header, setHeader] = useState<string>("");
  const [headerRed, setHeaderRed] = useState<string>("");

  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const ObjKey = Object.keys(currentFormData);
    let constCount = 0;
    for (let i = 0; i < ObjKey.length; i++) {
      if (currentFormData[ObjKey[i]]) {
        constCount++;
      }
    }
    setCurrentActive(constCount);
  }, [formData, step]);

  useEffect(() => {
    if (step === "step_1") {
      const authToken = localStorage.getItem("accessToken") as string;
      getOEMModelData(authToken).then((res: any) => {
        if (res?.success && step === "step_1") {
          console.log("%c Line:88 🥕 fieldmap", "color:#4fff4B", fieldmap);
          if (fieldmap && fieldmap["step_1"]?.fields) {
            fieldmap["step_1"].fields[1]["option"] = res?.data?.oems;
          } else {
            console.error("fields not found in fieldmap['step_1']");
          }
        }
      });
    }
    if (step === "step_2") {
      const authToken = localStorage.getItem("accessToken") as string;
      getLotService(authToken).then((res: any) => {
        //
        if (res.success && step === "step_2") {
          if (
            res?.data?.status &&
            res?.data?.data?.length !== 0 &&
            fieldmap["step_2"] &&
            fieldmap["step_2"].fields &&
            fieldmap["step_2"].fields[0]
          ) {
            const arr: any = [];
            res?.data?.data?.map((obj: any) =>
              arr.push({ identifier: obj?.identifier, name: obj?.identifier })
            );

            fieldmap["step_2"].fields[0]["option"] = arr;
            //  currentFormData.assembly_location = "DTX";
            dispatch(
              handleFieldData({
                step: "step_2",
                value: "",
                key: fieldmap["step_2"].fields[0].key,
              })
            );
          }
        }
      });
    }
    if (step === "step_3") {
      const authToken = localStorage.getItem("accessToken") as string;
      getLotService(authToken).then((res: any) => {
        //
        if (res.success && step === "step_3" && fieldmap) {
          if (res?.data?.status && res?.data?.data?.length !== 0) {
            const arr: any = [];
            res?.data?.data?.map((obj: any) =>
              arr.push({ identifier: obj?.identifier, name: obj?.identifier })
            );
            fieldmap["step_3"].fields[0]["option"] = arr;
            dispatch(
              handleFieldData({
                step: "step_3",
                value: "",
                key: fieldmap["step_3"].fields[0].key,
              })
            );
          }
        }
      });
      getPallet(authToken).then((res: any) => {
        if (res.success && step === "step_3" && fieldmap) {
          if (res?.data?.status && res?.data?.data?.length !== 0) {
            const arr: any = [];
            res?.data?.data?.map((obj: any) =>
              arr.push({ identifier: obj?.identifier, name: obj?.identifier })
            );
            fieldmap["step_3"].fields[1]["option"] = arr;
            dispatch(
              handleFieldData({
                step: "step_3",
                value: "",
                key: fieldmap["step_3"].fields[1].key,
              })
            );
          }
        }
      });
      getOEMModelData(authToken).then((res: any) => {
        //
        if (res.success && step === "step_3") {
          fieldmap["step_3"].fields[2]["option"] = res?.data?.oems;
        }
      });
    }
  }, [step]);

  useEffect(() => {
    if (step === "step_3" && formData[step]?.oem) {
      const authToken = localStorage.getItem("accessToken") as string;

      getOEMModelData(authToken).then((res: any) => {
        if (res.success && step === "step_3") {
          const oem: [] = res?.data?.oem_models?.filter(
            (obj: any) => obj?.oem === formData[step]?.oem
          );

          if (
            res?.data?.oem_models?.filter(
              (obj: any) => obj?.oem === formData[step]?.oem
            )?.length > 0
          ) {
            dispatch(
              handleFieldData({
                step: step,
                value: "",
                key: "oem_model",
              })
            );
            fieldmap["step_3"].fields[3]["option"] = oem;
          } else {
            dispatch(
              handleFieldData({
                step: step,
                value: "",
                key: "oem_model",
              })
            );
            fieldmap["step_3"].fields[3]["option"] = [];
          }
        }
      });
    }
  }, [formData[step]?.oem]);

  // useEffect(() => {
  //   const currentFormData = { ...formData[step] };
  //   const updatedFields: any = stepFieldMap;
  //   if (currentFormData?.oem) {
  //     const { oem_models, oems } = oemData;

  //     const oem_model_option = stepFieldMap["fields"].findIndex(
  //       (field: Field) => field.key === "oem_model"
  //     );
  //     updatedFields.fields[oem_model_option].option = oem_models?.filter(
  //       (obj: any) => obj?.oem === formData[step]["oem"]
  //     );
  //     if (
  //       oem_models?.filter((obj: any) => obj?.oem === formData[step]["oem"])
  //         ?.length > 0
  //     ) {
  //       dispatch(
  //         handleFieldData({
  //           step: step,
  //           value: oem_models?.filter(
  //             (obj: any) => obj?.oem === formData[step]["oem"]
  //           )[0]?.code,
  //           key: "oem_model",
  //         })
  //       );
  //     }
  //     setStepFieldMap({ ...updatedFields });
  //   }
  // }, [formData[step]?.oem]);
  useEffect(() => {
    if (step === "step_18") {
      getDataFromModuleId();
    } else {
      if (fieldmap["step_18"]) {
        const currentDataObj = { ...formData["step_18"] };
        const objectKeys = Object.keys(currentDataObj);
        for (let i = 0; i < objectKeys.length; i++) {
          const key = objectKeys[i];
          // if (key === "kpi1") {
          //   continue;
          // }
          delete currentDataObj[key];
        }
        dispatch(resetStepData({ step: step, value: currentDataObj }));
        dispatch(resetStepData({ step: "step_18", value: currentDataObj }));
        fieldmap["step_18"].fields = [
          {
            key: "kpi1",
            name: "Scan Module ID",
            type: "HIS",
          },
        ];
        setHeader("");
        setHeaderRed("");
        stepSop.step_18 = "";
      }
    }
  }, [formData["step_18"]?.kpi1, step]);

  // const handleGetData = () => {
  //   getDataFromModuleId();
  // };
  const getDataFromModuleId = () => {
    if (formData["step_18"]?.kpi1) {
      setLoadingGet(true);
      dispatch(handleModule(0));
      getModuleById(formData["step_18"]?.kpi1.trim())
        .then((res: any) => {
          if (res?.success && res?.data) {
            if (res?.data?.data) {
              dispatch(
                handleModule(
                  res?.data?.data?.completedPhase
                    ? res?.data?.data?.completedPhase + 1
                    : 0 + 1
                )
              );
              if (!res?.data?.data?.reject) {
                setHeaderRed("");
              }
              if (res?.data?.data?.reject) {
                setHeader("");
                setHeaderRed(
                  `${formData["step_18"]?.kpi1} rejected due to ${res?.data?.data?.rejectReason}`
                );
                setLoading(false);
                setLoadingGet(false);
                fieldmap["step_18"].fields = [
                  {
                    key: "kpi1",
                    name: "Scan Module ID",
                    type: "HIS",
                  },
                ];
                return;
              }

              const currentDataObj = { ...formData["step_18"] };
              const objectKeys = Object.keys(currentDataObj);
              for (let i = 0; i < objectKeys.length; i++) {
                const key = objectKeys[i];
                if (key === "kpi1") {
                  continue;
                }
                delete currentDataObj[key];
              }
              dispatch(resetStepData({ step: step, value: currentDataObj }));

              setModule(res?.data?.data?.completedPhase);
              dispatch(handleModule(res?.data?.data?.completedPhase + 1));
              if (res?.data?.data?.completedPhase === 0) {
                setHeader("HIS 1: Physical Inspection");
                dispatch(resetToggle(!formData.toggle));

                stepSop.step_18 =
                  "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/ERihfurHg8VFpG2GwdJ38MwBQYbhvuZzvrj0yiDPZkgaRQ?e=sJKq2y";
                {
                  formData["step_18"]?.kpi1.startsWith("AAUAAB")
                    ? (fieldmap["step_18"].view_media = [
                        {
                          type: "image",
                          key: "top_image",
                          name: "Module Top Image",
                          src: "AAB_TOP.jpeg",
                        },
                        {
                          type: "image",
                          key: "bottom_image",
                          name: "Module Bottom Image",
                          src: "AAB_BOTTOM.jpeg",
                        },
                        {
                          type: "image",
                          key: "front_image",
                          name: "Module Front Image",
                          src: "AAB_FRONT.jpeg",
                        },
                        {
                          type: "image",
                          key: "back_image",
                          name: "Module Back Image",
                          src: "AAB_BACK.jpeg",
                        },
                        {
                          type: "image",
                          key: "left_image",
                          name: "Module Left Image",
                          src: "AAB_LEFT.jpeg",
                        },
                        {
                          type: "image",
                          key: "right_image",
                          name: "Module Right Image",
                          src: "AAB_RIGHT.jpeg",
                        },
                      ])
                    : (fieldmap["step_18"].view_media = [
                        {
                          type: "image",
                          key: "top_image",
                          name: "Module Top Image",
                          src: "AAD_TOP.jpeg",
                        },
                        {
                          type: "image",
                          key: "bottom_image",
                          name: "Module Bottom Image",
                          src: "AAD_BOTTOM.jpeg",
                        },
                        {
                          type: "image",
                          key: "front_image",
                          name: "Module Front Image",
                          src: "AAD_FRONT.jpeg",
                        },
                        {
                          type: "image",
                          key: "back_image",
                          name: "Module Back Image",
                          src: "AAD_BACK.jpeg",
                        },
                        {
                          type: "image",
                          key: "left_image",
                          name: "Module Left Image",
                          src: "AAD_LEFT.jpeg",
                        },
                        {
                          type: "image",
                          key: "right_image",
                          name: "Module Right Image",
                          src: "AAD_RIGHT.jpeg",
                        },
                      ]);
                }
                fieldmap["step_18"].upload_media = [
                  {
                    key: "top_image",
                    name: "Step 1: Take Top image",
                    type: "image",
                  },
                  {
                    key: "bottom_image",
                    name: "Step 2: Take Bottom image",
                    type: "image",
                  },
                  {
                    key: "front_image",
                    name: "Step 3: Take Front image",
                    type: "image",
                  },
                  {
                    key: "back_image",
                    name: "Step 4: Take Back Side image",
                    type: "image",
                  },
                  {
                    key: "left_image",
                    name: "Step 5: Take Left Side image",
                    type: "image",
                  },
                  {
                    key: "right_image",
                    name: "Step 6: Take Right Side image",
                    type: "image",
                  },
                ];
                {
                  formData["step_18"]?.kpi1.startsWith("AAUAAD") ||
                  formData["step_18"]?.kpi1.startsWith("AAUAAC")
                    ? (fieldmap["step_18"].fields = [
                        {
                          key: "kpi1",
                          name: "Scan Module ID",
                          type: "HIS",
                        },
                        {
                          key: "top_image",
                          name: "Step 1: Take Top image",
                          type: "file",
                        },
                        {
                          key: "bottom_image",
                          name: "Step 2: Take Bottom image",
                          type: "file",
                        },
                        {
                          key: "front_image",
                          name: "Step 3: Take Front Side image",
                          type: "file",
                        },
                        {
                          key: "back_image",
                          name: "Step 4: Take Back Side image",
                          type: "file",
                        },
                        {
                          key: "left_image",
                          name: "Step 5: Take Left Side image",
                          type: "file",
                        },
                        {
                          key: "right_image",
                          name: "Step 6: Take Right Side image",
                          type: "file",
                        },
                        {
                          key: "oem_serial_id",
                          name: "Scan OEM ID",
                          type: "field",
                        },
                        {
                          key: "weight",
                          name: "Module Weight (lbs)",
                          type: "field",
                          validate: {
                            range: [0, 19.99],
                          },
                          description:
                            "If the weight is equal to or greater than 20 lbs then module will be rejected.",
                        },
                        {
                          key: "attached_module_id",
                          name: "Scan Attached Module ID",
                          type: "field",
                          description:
                            "Enter whitespace (press Space once) if you don't have attached Module",
                        },
                        {
                          key: "module_thickness",
                          name: "Module Thickness in mm",
                          type: "field",
                          validate: {
                            range: [0, 71.99],
                          },
                          description:
                            "If the thickness is equal to or greater than 72 mm then module will be rejected.",
                        },
                        {
                          key: "rejection_reason",
                          name: "Reject or Continue Processing",
                          // validate: {
                          //   match: true,
                          // },
                          type: "status",
                          option: [
                            "PHY1 - Bloated Modules",
                            "PHY2 - Physical Damage of casing",
                            "PHY3 - Physical Damage of Terminals",
                            "PHY4 - Corroded Terminals",
                            "PHY5 - Module Weight",
                          ],
                          sidenav: false,
                        },
                      ])
                    : (fieldmap["step_18"].fields = [
                        {
                          key: "kpi1",
                          name: "Scan Module ID",
                          type: "HIS",
                        },
                        {
                          key: "top_image",
                          name: "Step 1: Take Top image",
                          type: "file",
                        },
                        {
                          key: "bottom_image",
                          name: "Step 2: Take Bottom image",
                          type: "file",
                        },
                        {
                          key: "front_image",
                          name: "Step 3: Take Front Side image",
                          type: "file",
                        },
                        {
                          key: "back_image",
                          name: "Step 4: Take Back Side image",
                          type: "file",
                        },
                        {
                          key: "left_image",
                          name: "Step 5: Take Left Side image",
                          type: "file",
                        },
                        {
                          key: "right_image",
                          name: "Step 6: Take Right Side image",
                          type: "file",
                        },
                        {
                          key: "oem_serial_id",
                          name: "Scan OEM ID",
                          type: "field",
                        },
                        {
                          key: "weight",
                          name: "Module Weight (lbs)",
                          type: "field",
                        },

                        {
                          key: "module_thickness",
                          name: "Module Thickness in mm",
                          type: "field",
                          validate: {
                            range: [68, 73],
                          },
                        },
                        {
                          key: "rejection_reason",
                          name: "Reject or Continue Processing",
                          // validate: {
                          //   match: true,
                          // },
                          type: "status",
                          option: [
                            "PHY1 - Bloated Modules",
                            "PHY2 - Physical Damage of casing",
                            "PHY3 - Physical Damage of Terminals",
                            "PHY4 - Corroded Terminals",
                            "PHY5 - Module Weight",
                          ],
                          sidenav: false,
                        },
                      ]);
                }
              } else if (res?.data?.data?.completedPhase === 1) {
                setHeader("HIS 2: ACIR, OCV, Continuity");
                stepSop.step_18 =
                  "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EWQ57pyAXY9HqdKBudkFKVkB2EpMize04vq0D0OnBhZUow?e=TxUcyd";
                delete fieldmap["step_18"].view_media;
                delete fieldmap["step_18"].upload_media;
                dispatch(resetToggle(!formData.toggle));

                fieldmap["step_18"].fields = [
                  // {
                  //   key: "label",
                  //   name: "HIS 2: ACIR, OCV, Continuity",
                  //   type: "label",
                  // },
                  {
                    key: "kpi1",
                    name: "Scan Module ID",
                    type: "HIS",
                  },

                  {
                    key: "acir",
                    name: "ACIR & OCV Measurement",
                    type: "file_read",
                    read_field: ["ocv", "acir"],
                    description: `If the ACIR measurement is equal to or greater than 4 mΩ and OCV measurement will out range of 5V-8.6V then module will be rejected.`,
                  },
                  {
                    key: "continuity",
                    name: "Continuity",
                    type: "select",
                    option: ["true", "false"],
                    validate: {
                      match: "true",
                    },
                  },
                  {
                    key: "rejection_reason",
                    name: "Reject or Continue Processing",
                    type: "status",
                    option: [
                      "ECL1 - Low OCV",
                      "ECL2 - High ACIR",
                      "ECL3 - Continous Both Ways",
                    ],
                    sidenav: false,
                  },
                ];
              } else if (res?.data?.data?.completedPhase === 2) {
                setHeader("HIS 3: HiPOT");
                stepSop.step_18 =
                  "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EVvotQPpO9JHqi0tbraEIlYBNcubHfzeJcLRHPYu8IsmsQ?e=OB9JDF";
                delete fieldmap["step_18"].view_media;
                delete fieldmap["step_18"].upload_media;
                dispatch(resetToggle(!formData.toggle));

                fieldmap["step_18"].fields = [
                  {
                    key: "kpi1",
                    name: "Scan Module ID",
                    type: "HIS",
                  },
                  // {
                  //   key: "kpi1",
                  //   name: "HIS 3 : HiPOT",
                  //   type: "label",
                  // },
                  // {
                  //   key: "battery_module",
                  //   name: "Scan Module ID",
                  //   type: "field",
                  // },
                  {
                    key: "hipot_measurement",
                    name: "HiPOT Measurement",
                    type: "file_read",
                    read_field: ["hipot_measurement"],
                    file_validate: [{ field: "hipot_measurement", min: 1 }],
                    description:
                      "If the HiPOT Measurement is equal to or greater than 22 V then module will be rejected.",
                  },
                  {
                    key: "rejection_reason",
                    name: "Reject or Continue Processing",
                    type: "status",
                    option: ["ELS1 - Improper insulation "],
                    sidenav: false,
                  },
                ];
              } else if (res?.data?.data?.completedPhase === 3) {
                setHeader("HIS 4: HPPC/Relaxation");
                stepSop.step_18 =
                  "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EUzRR7UWOrFEssMH-RXVScEBahZkUG18_7KTEcRUXVmwRA?e=W5JhBc";
                delete fieldmap["step_18"].view_media;
                delete fieldmap["step_18"].upload_media;
                dispatch(resetToggle(!formData.toggle));

                fieldmap["step_18"].fields = [
                  {
                    key: "kpi1",
                    name: "Scan Module ID",
                    type: "HIS",
                  },
                  // {
                  //   key: "kpi1",
                  //   name: "HIS 4: HPPC/Relaxation",
                  //   type: "label",
                  // },
                  {
                    key: "test_channel_id",
                    name: "Scan Test Channel ID",
                    type: "field",
                  },
                  {
                    key: "recipe_excel",
                    name: "Recipe Image",
                    type: "excel",
                    hidden: false,
                  },
                  // {
                  //   key: "next_step",
                  //   type: "reset",
                  //   name: "Add Another",
                  //   hidden: false,
                  //   sidenav: false,
                  // },
                  {
                    key: "rejection_reason",
                    name: "Reject or Continue Processing",
                    type: "status",
                    option: ["ECH5 - Interrupted HPPC Test"],
                    sidenav: false,
                  },
                ];
              } else if (res?.data?.data?.completedPhase === 4) {
                setHeader("HIS 5: EIS & ACIR");
                stepSop.step_18 =
                  "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/Eea0SbO-XVZFqT39YHlRo54BGhLaPlg1e7zy4l62A9IR7w?e=2HigpV";
                delete fieldmap["step_18"].view_media;
                delete fieldmap["step_18"].upload_media;
                dispatch(resetToggle(!formData.toggle));
                if (res?.data?.data?.skipped) {
                  fieldmap["step_18"].fields = [
                    {
                      key: "kpi1",
                      name: "Scan Module ID",
                      type: "HIS",
                    },

                    {
                      key: "test_channel_id_eis",
                      name: "Scan Test Channel ID EIS",
                      type: "field",
                    },
                    {
                      key: "eis_measurement_file",
                      name: "EIS Measurement",
                      type: "excel",
                    },
                    {
                      key: "rejection_reason",
                      name: "Reject or Continue Processing",
                      type: "status",
                      option: ["ECH6 -Bad EIS sweep"],
                      sidenav: false,
                    },
                  ];
                } else {
                  fieldmap["step_18"].fields = [
                    {
                      key: "kpi1",
                      name: "Scan Module ID",
                      type: "HIS",
                    },
                    // {
                    //   key: "kpi1",
                    //   name: "HIS 5: EIS & ACIR",
                    //   type: "label",
                    // },
                    {
                      key: "test_channel_id_acir",
                      name: "Scan Test Channel ID ACIR",
                      type: "field",
                    },
                    {
                      key: "acir_measurement_file",
                      name: "ACIR Measurement",
                      type: "excel",
                    },
                    {
                      key: "test_channel_id_eis",
                      name: "Scan Test Channel ID EIS",
                      type: "field",
                    },
                    {
                      key: "eis_measurement_file",
                      name: "EIS Measurement",
                      type: "excel",
                    },
                    {
                      key: "rejection_reason",
                      name: "Reject or Continue Processing",
                      type: "status",
                      option: ["ECH6 -Bad EIS sweep"],
                      sidenav: false,
                    },
                  ];
                }
              } else if (res?.data?.data?.completedPhase === 5) {
                setHeader("HIS 6: Capacity Measurement");
                stepSop.step_18 =
                  "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EWz99a6s5HBPuVfrzzq4crsB07Oy20WvRdR5Dcz5_N8ZNQ?e=3sfiEX";
                delete fieldmap["step_18"].view_media;
                delete fieldmap["step_18"].upload_media;
                dispatch(resetToggle(!formData.toggle));

                fieldmap["step_18"].fields = [
                  {
                    key: "kpi1",
                    name: "Scan Module ID",
                    type: "HIS",
                  },
                  // {
                  //   key: "kpi1",
                  //   name: "HIS 6: Capacity Measurement",
                  //   type: "label",
                  // },
                  {
                    key: "test_channel_id",
                    name: "Scan Test Channel ID",
                    type: "field",
                  },
                  {
                    key: "capacity_measurement_file",
                    name: "Capacity Measurement",
                    type: "excel",
                  },
                  {
                    key: "rejection_reason",
                    name: "Reject or Continue Processing",
                    type: "status",
                    option: [
                      "ECH1 - Low SoH",
                      "ECH2 - High Delta SoH for Linked Modules",
                      "ECH3 - Interrupted Test: Over Heating",
                      "ECH4 - Interrupted Test: Under Control Temperature",
                    ],
                    sidenav: false,
                  },
                ];
              } else if (res?.data?.data?.completedPhase === 6) {
                setHeader(
                  `${formData["step_18"]?.kpi1} Has completed all HIS!`
                );
                stepSop.step_18 = "";
                fieldmap["step_18"].fields = [
                  {
                    key: "kpi1",
                    name: "Scan Module ID",
                    type: "HIS",
                  },
                ];
                delete fieldmap["step_18"].view_media;
                delete fieldmap["step_18"].upload_media;
                dispatch(resetToggle(!formData.toggle));
              }
            } else {
              //
              setHeader("HIS 1: Physical Inspection");
              setModule(0);
              dispatch(handleModule(1));
              const currentDataObj = { ...formData["step_18"] };
              const objectKeys = Object.keys(currentDataObj);
              for (let i = 0; i < objectKeys.length; i++) {
                const key = objectKeys[i];
                if (key === "kpi1") {
                  continue;
                }
                delete currentDataObj[key];
              }
              dispatch(resetStepData({ step: step, value: currentDataObj }));
              dispatch(resetToggle(!formData.toggle));
              stepSop.step_18 =
                "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/ERihfurHg8VFpG2GwdJ38MwBQYbhvuZzvrj0yiDPZkgaRQ?e=sJKq2y";
              {
                formData["step_18"]?.kpi1.startsWith("AAUAAB")
                  ? (fieldmap["step_18"].view_media = [
                      {
                        type: "image",
                        key: "top_image",
                        name: "Module Top Image",
                        src: "AAB_TOP.jpeg",
                      },
                      {
                        type: "image",
                        key: "bottom_image",
                        name: "Module Bottom Image",
                        src: "AAB_BOTTOM.jpeg",
                      },
                      {
                        type: "image",
                        key: "front_image",
                        name: "Module Front Image",
                        src: "AAB_FRONT.jpeg",
                      },
                      {
                        type: "image",
                        key: "back_image",
                        name: "Module Back Image",
                        src: "AAB_BACK.jpeg",
                      },
                      {
                        type: "image",
                        key: "left_image",
                        name: "Module Left Image",
                        src: "AAB_LEFT.jpeg",
                      },
                      {
                        type: "image",
                        key: "right_image",
                        name: "Module Right Image",
                        src: "AAB_RIGHT.jpeg",
                      },
                    ])
                  : (fieldmap["step_18"].view_media = [
                      {
                        type: "image",
                        key: "top_image",
                        name: "Module Top Image",
                        src: "AAD_TOP.jpeg",
                      },
                      {
                        type: "image",
                        key: "bottom_image",
                        name: "Module Bottom Image",
                        src: "AAD_BOTTOM.jpeg",
                      },
                      {
                        type: "image",
                        key: "front_image",
                        name: "Module Front Image",
                        src: "AAD_FRONT.jpeg",
                      },
                      {
                        type: "image",
                        key: "back_image",
                        name: "Module Back Image",
                        src: "AAD_BACK.jpeg",
                      },
                      {
                        type: "image",
                        key: "left_image",
                        name: "Module Left Image",
                        src: "AAD_LEFT.jpeg",
                      },
                      {
                        type: "image",
                        key: "right_image",
                        name: "Module Right Image",
                        src: "AAD_RIGHT.jpeg",
                      },
                    ]);
              }
              fieldmap["step_18"].upload_media = [
                {
                  key: "top_image",
                  name: "Step 1: Take Top image",
                  type: "image",
                },
                {
                  key: "bottom_image",
                  name: "Step 2: Take Bottom image",
                  type: "image",
                },
                {
                  key: "front_image",
                  name: "Step 3: Take Front image",
                  type: "image",
                },
                {
                  key: "back_image",
                  name: "Step 4: Take Back Side image",
                  type: "image",
                },
                {
                  key: "left_image",
                  name: "Step 5: Take Left Side image",
                  type: "image",
                },
                {
                  key: "right_image",
                  name: "Step 6: Take Right Side image",
                  type: "image",
                },
              ];
              {
                formData["step_18"]?.kpi1.startsWith("AAUAAD") ||
                formData["step_18"]?.kpi1.startsWith("AAUAAC")
                  ? (fieldmap["step_18"].fields = [
                      {
                        key: "kpi1",
                        name: "Scan Module ID",
                        type: "HIS",
                      },
                      {
                        key: "top_image",
                        name: "Step 1: Take Top image",
                        type: "file",
                      },
                      {
                        key: "bottom_image",
                        name: "Step 2: Take Bottom image",
                        type: "file",
                      },
                      {
                        key: "front_image",
                        name: "Step 3: Take Front Side image",
                        type: "file",
                      },
                      {
                        key: "back_image",
                        name: "Step 4: Take Back Side image",
                        type: "file",
                      },
                      {
                        key: "left_image",
                        name: "Step 5: Take Left Side image",
                        type: "file",
                      },
                      {
                        key: "right_image",
                        name: "Step 6: Take Right Side image",
                        type: "file",
                      },
                      {
                        key: "oem_serial_id",
                        name: "Scan OEM ID",
                        type: "field",
                      },
                      {
                        key: "weight",
                        name: "Module Weight (lbs)",
                        type: "field",
                        validate: {
                          range: [0, 19.99],
                        },
                        description:
                          "If the weight is equal to or greater than 20 lbs then module will be rejected.",
                      },
                      {
                        key: "attached_module_id",
                        name: "Scan Attached Module ID",
                        type: "field",
                        description:
                          "Enter whitespace (press Space once) if you don't have attached Module",
                      },
                      {
                        key: "module_thickness",
                        name: "Module Thickness in mm",
                        type: "field",
                        validate: {
                          range: [0, 71.99],
                        },
                        description:
                          "If the thickness is equal to or greater than 72 mm then module will be rejected.",
                      },
                      {
                        key: "rejection_reason",
                        name: "Reject or Continue Processing",
                        // validate: {
                        //   match: true,
                        // },
                        type: "status",
                        option: [
                          "PHY1 - Bloated Module",
                          "PHY2 - Physical Damage of casing",
                          "PHY3 - Physical Damage of Terminals",
                          "PHY4 - Corroded Terminals",
                          "PHY5 - Module Weight",
                        ],
                        sidenav: false,
                      },
                    ])
                  : (fieldmap["step_18"].fields = [
                      {
                        key: "kpi1",
                        name: "Scan Module ID",
                        type: "HIS",
                      },
                      {
                        key: "top_image",
                        name: "Step 1: Take Top image",
                        type: "file",
                      },
                      {
                        key: "bottom_image",
                        name: "Step 2: Take Bottom image",
                        type: "file",
                      },
                      {
                        key: "front_image",
                        name: "Step 3: Take Front Side image",
                        type: "file",
                      },
                      {
                        key: "back_image",
                        name: "Step 4: Take Back Side image",
                        type: "file",
                      },
                      {
                        key: "left_image",
                        name: "Step 5: Take Left Side image",
                        type: "file",
                      },
                      {
                        key: "right_image",
                        name: "Step 6: Take Right Side image",
                        type: "file",
                      },

                      {
                        key: "oem_serial_id",
                        name: "Scan OEM ID",
                        type: "field",
                      },
                      {
                        key: "weight",
                        name: "Module Weight (lbs)",
                        type: "field",
                      },
                      {
                        key: "module_thickness",
                        name: "Module Thickness in mm",
                        type: "field",
                        validate: {
                          range: [68, 73],
                        },
                      },
                      {
                        key: "rejection_reason",
                        name: "Reject or Continue Processing",
                        // validate: {
                        //   match: true,
                        // },
                        type: "status",
                        option: [
                          "PHY1 - Bloated Module",
                          "PHY2 - Physical Damage of casing",
                          "PHY3 - Physical Damage of Terminals",
                          "PHY4 - Corroded Terminals",
                          "PHY5 - Module Weight",
                        ],
                        sidenav: false,
                      },
                    ]);
              }
            }
          }
          setLoadingGet(false);
          if (!res?.success) {
            setErOpen(true);
            setParamError(errorHandle(res?.message));
            const currentDataObj = { ...formData["step_18"] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];
              // if (key === "kpi1") {
              //   continue;
              // }
              delete currentDataObj[key];
            }
            dispatch(resetStepData({ step: step, value: currentDataObj }));
            setHeader("");
            setHeaderRed("");
            stepSop.step_18 = "";
            fieldmap["step_18"].fields = [
              {
                key: "kpi1",
                name: "Scan Module ID",
                type: "HIS",
              },
            ];
            delete fieldmap["step_18"].view_media;
            delete fieldmap["step_18"].upload_media;
          }
        })
        .catch((e: any) => {
          setLoadingGet(false);
          setErOpen(true);
          setParamError(errorHandle(e?.response?.data?.message));
        });
    }
  };
  // useEffect(() => {
  //   if (moduleData) {
  //     setModule((prev) => moduleData);
  //   }
  // }, [moduleData]);

  const handleSubmit = () => {
    //
    if (step == "step_1") {
      setLoading(true);
      handleCreateLot(formData[step], userData.id)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        }
        setLoading(false);
      });
    } else if (step == "step_2") {
      setLoading(true);
      handleCreatePallet(formData[step], userData.id)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        }
        setLoading(false);
      });
    } else if (step == "step_3") {
      setLoading(true);

      createBinService(formData[step], userData.id)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        }
        setLoading(false);
      });
    } else if (step == "step_11") {
      SABProcessService(formData[step], userData.id)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        }
      });
    } else if (step == "step_12") {
      setLoading(true);
      createModulePackagingService(formData[step], userData.id)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        }
        setLoading(false);
      });
    } else if (step == "step_18" && formData["step_18"]?.kpi1) {
      if (moduleData === 1) {
        const data: any = formData["step_18"];
        setLoading(true);
        createElectricalInspectionOne(data, userData.id)?.then((res) => {
          if (!res.success) {
            setParamError(errorHandle(res.message));
            setErOpen(true);
            setLoading(false);
          } else {
            // setModule(1);
            const currentDataObj = { ...formData["step_18"] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];
              delete currentDataObj[key];
            }
            setLoading(false);

            dispatch(resetStepData({ step: step, value: currentDataObj }));
            dispatch(resetToggle(!formData.toggle));

            delete fieldmap["step_18"].view_media;
            delete fieldmap["step_18"].upload_media;
            stepSop.step_18 = "";
            fieldmap["step_18"].fields = [
              {
                key: "kpi1",
                name: "Scan Module ID",
                type: "HIS",
              },
            ];
            setHeader("");
          }
        });
      } else if (moduleData === 2) {
        const data = formData["step_18"];
        setLoading(true);
        createElectricalInspectionTwo(data, userData.id)?.then((res) => {
          if (!res.success) {
            setParamError(errorHandle(res.message));
            setErOpen(true);
            setLoading(false);
          } else {
            // setModule(2);
            const currentDataObj = { ...formData["step_18"] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];

              delete currentDataObj[key];
            }
            setLoading(false);

            dispatch(resetStepData({ step: step, value: currentDataObj }));
            dispatch(resetToggle(!formData.toggle));
            stepSop.step_18 = "";

            fieldmap["step_18"].fields = [
              {
                key: "kpi1",
                name: "Scan Module ID",
                type: "HIS",
              },
            ];
            setHeader("");
          }
        });
      } else if (moduleData === 3) {
        const data = formData["step_18"];

        setLoading(true);
        createElectricalInspectionThree(data, userData.id)?.then((res) => {
          if (!res.success) {
            setParamError(errorHandle(res.message));
            setErOpen(true);
            setLoading(false);
          } else {
            setLoading(false);

            // setModule(3);
            const currentDataObj = { ...formData["step_18"] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];
              delete currentDataObj[key];
            }

            dispatch(resetStepData({ step: step, value: currentDataObj }));
            dispatch(resetToggle(!formData.toggle));

            stepSop.step_18 = "";

            fieldmap["step_18"].fields = [
              {
                key: "kpi1",
                name: "Scan Module ID",
                type: "HIS",
              },
            ];
            setHeader("");
          }
        });
      } else if (moduleData === 4) {
        const data = formData["step_18"];
        setLoading(true);

        createElectricalInspectionFour(data, userData.id)?.then((res) => {
          if (!res.success) {
            setParamError(errorHandle(res.message));
            setErOpen(true);
            setLoading(false);
          } else {
            const currentDataObj = { ...formData["step_18"] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];
              delete currentDataObj[key];
            }
            setLoading(false);

            dispatch(resetStepData({ step: step, value: currentDataObj }));
            dispatch(resetToggle(!formData.toggle));
            stepSop.step_18 = "";

            fieldmap["step_18"].fields = [
              {
                key: "kpi1",
                name: "Scan Module ID",
                type: "HIS",
              },
            ];
            setHeader("");
          }
        });
      } else if (moduleData === 5) {
        const data = formData["step_18"];
        setLoading(true);

        createElectricalInspectionFive(data, userData.id)?.then((res) => {
          if (!res.success) {
            setParamError(errorHandle(res.message));
            setErOpen(true);
            setLoading(false);
          } else {
            const currentDataObj = { ...formData["step_18"] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];
              delete currentDataObj[key];
            }
            setLoading(false);

            dispatch(resetStepData({ step: step, value: currentDataObj }));
            dispatch(resetToggle(!formData.toggle));
            stepSop.step_18 = "";

            fieldmap["step_18"].fields = [
              {
                key: "kpi1",
                name: "Scan Module ID",
                type: "HIS",
              },
            ];
            setHeader("");
          }
        });
      } else if (moduleData === 6) {
        const data = formData["step_18"];
        setLoading(true);

        createElectricalInspectionSix(data, userData.id)?.then((res) => {
          if (!res.success) {
            setParamError(errorHandle(res.message));
            setErOpen(true);
            setLoading(false);
          } else {
            const currentDataObj = { ...formData["step_18"] };
            const objectKeys = Object.keys(currentDataObj);
            for (let i = 0; i < objectKeys.length; i++) {
              const key = objectKeys[i];

              delete currentDataObj[key];
            }
            setHeader("");
            stepSop.step_18 = "";
            setLoading(false);

            fieldmap["step_18"].fields = [
              {
                key: "kpi1",
                name: "Scan Module ID",
                type: "field",
              },
            ];
            dispatch(resetStepData({ step: step, value: currentDataObj }));
            dispatch(resetToggle(!formData.toggle));
          }
        });
      }
    }
  };

  const errorHandle = (errors: any) => {
    const new_err = [];
    new_err.push(errors?.message);
    return new_err;
  };

  const handleErrorClose = useCallback(() => {
    setErOpen(false);
    setParamError([]);
  }, [paramError, setParamError]);

  useEffect(() => {
    if (step === "step_18") {
      if (moduleData === 1) {
        if (
          formData["step_18"]?.top_image &&
          formData.step_18?.top_image instanceof File
        ) {
          if (formData["step_18"]?.top_image.type.startsWith("image/")) {
            const data: any = formData["step_18"];
            const vFiles = data?.top_image;
            setLoadingSign(true);
            getSignUrl({ fileName: data?.top_image?.name, his: 1 }).then(
              (res: any) => {
                if (res?.success && res?.data && res?.data?.data) {
                  setLoadingSign(false);

                  const xhr = new XMLHttpRequest();
                  xhr.open("PUT", res?.data?.data?.signUrls, true);
                  xhr.setRequestHeader("Content-Type", vFiles?.type);
                  xhr.onload = () => {
                    dispatch(handleImageLoading(true));
                  };
                  xhr.onreadystatechange = () => {
                    if (
                      xhr.readyState === XMLHttpRequest.DONE &&
                      xhr.status === 200
                    ) {
                      dispatch(
                        handleFieldData({
                          step: "step_18",
                          value: res?.data?.data?.url,
                          key: "top_image",
                        })
                      );
                    }
                  };

                  xhr.send(vFiles);
                }
              }
            );
          }
        }
      }
    }
  }, [formData["step_18"]?.top_image]);

  useEffect(() => {
    if (step === "step_18") {
      if (moduleData === 1) {
        if (
          formData["step_18"]?.bottom_image &&
          formData.step_18?.bottom_image instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.bottom_image;
          setLoadingSign(true);
          getSignUrl({ fileName: data?.bottom_image?.name, his: 1 }).then(
            (res: any) => {
              if (res?.success && res?.data && res?.data?.data) {
                setLoadingSign(false);

                const xhr = new XMLHttpRequest();
                xhr.open("PUT", res?.data?.data?.signUrls, true);
                xhr.setRequestHeader("Content-Type", vFiles?.type);
                xhr.onload = () => {
                  dispatch(handleImageLoading(true));
                };
                xhr.onreadystatechange = () => {
                  if (
                    xhr.readyState === XMLHttpRequest.DONE &&
                    xhr.status === 200
                  ) {
                    dispatch(
                      handleFieldData({
                        step: "step_18",
                        value: res?.data?.data?.url,
                        key: "bottom_image",
                      })
                    );
                  }
                };
                xhr.send(vFiles);
              }
            }
          );
        }
      }
    }
  }, [formData["step_18"]?.bottom_image]);

  useEffect(() => {
    if (step === "step_18") {
      if (moduleData === 1) {
        if (
          formData["step_18"]?.front_image &&
          formData.step_18?.front_image instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.front_image;
          setLoadingSign(true);
          getSignUrl({ fileName: data?.front_image?.name, his: 1 }).then(
            (res: any) => {
              if (res?.success && res?.data && res?.data?.data) {
                setLoadingSign(false);

                const xhr = new XMLHttpRequest();
                xhr.open("PUT", res?.data?.data?.signUrls, true);
                xhr.setRequestHeader("Content-Type", vFiles?.type);
                xhr.onload = () => {
                  dispatch(handleImageLoading(true));
                };
                xhr.onreadystatechange = () => {
                  if (
                    xhr.readyState === XMLHttpRequest.DONE &&
                    xhr.status === 200
                  ) {
                    dispatch(
                      handleFieldData({
                        step: "step_18",
                        value: res?.data?.data?.url,
                        key: "front_image",
                      })
                    );
                  }
                };
                xhr.send(vFiles);
              }
            }
          );
        }
      }
    }
  }, [formData["step_18"]?.front_image]);

  useEffect(() => {
    if (step === "step_18") {
      if (moduleData === 1) {
        if (
          formData["step_18"]?.back_image &&
          formData.step_18?.back_image instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.back_image;
          setLoadingSign(true);
          getSignUrl({ fileName: data?.back_image?.name, his: 1 }).then(
            (res: any) => {
              if (res?.success && res?.data && res?.data?.data) {
                setLoadingSign(false);

                const xhr = new XMLHttpRequest();
                xhr.open("PUT", res?.data?.data?.signUrls, true);
                xhr.setRequestHeader("Content-Type", vFiles?.type);
                xhr.onload = () => {
                  dispatch(handleImageLoading(true));
                };
                xhr.onreadystatechange = () => {
                  if (
                    xhr.readyState === XMLHttpRequest.DONE &&
                    xhr.status === 200
                  ) {
                    dispatch(
                      handleFieldData({
                        step: "step_18",
                        value: res?.data?.data?.url,
                        key: "back_image",
                      })
                    );
                  }
                };
                xhr.send(vFiles);
              }
            }
          );
        }
      }
    }
  }, [formData["step_18"]?.back_image]);

  useEffect(() => {
    if (step === "step_18") {
      if (moduleData === 1) {
        if (
          formData["step_18"]?.left_image &&
          formData.step_18?.left_image instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.left_image;
          setLoadingSign(true);
          getSignUrl({ fileName: data?.left_image?.name, his: 1 }).then(
            (res: any) => {
              if (res?.success && res?.data && res?.data?.data) {
                setLoadingSign(false);

                const xhr = new XMLHttpRequest();
                xhr.open("PUT", res?.data?.data?.signUrls, true);
                xhr.setRequestHeader("Content-Type", vFiles?.type);
                xhr.onload = () => {
                  dispatch(handleImageLoading(true));
                };
                xhr.onreadystatechange = () => {
                  if (
                    xhr.readyState === XMLHttpRequest.DONE &&
                    xhr.status === 200
                  ) {
                    dispatch(
                      handleFieldData({
                        step: "step_18",
                        value: res?.data?.data?.url,
                        key: "left_image",
                      })
                    );
                  }
                };
                xhr.send(vFiles);
              }
            }
          );
        }
      }
    }
  }, [formData["step_18"]?.left_image]);

  useEffect(() => {
    if (step === "step_18") {
      if (moduleData === 1) {
        if (
          formData["step_18"]?.right_image &&
          formData.step_18?.right_image instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.right_image;
          setLoadingSign(true);
          getSignUrl({ fileName: data?.right_image?.name, his: 1 }).then(
            (res: any) => {
              if (res?.success && res?.data && res?.data?.data) {
                setLoadingSign(false);

                const xhr = new XMLHttpRequest();
                xhr.open("PUT", res?.data?.data?.signUrls, true);
                xhr.setRequestHeader("Content-Type", vFiles?.type);
                xhr.onload = () => {
                  dispatch(handleImageLoading(true));
                };
                xhr.onreadystatechange = () => {
                  if (
                    xhr.readyState === XMLHttpRequest.DONE &&
                    xhr.status === 200
                  ) {
                    dispatch(
                      handleFieldData({
                        step: "step_18",
                        value: res?.data?.data?.url,
                        key: "right_image",
                      })
                    );
                  }
                };
                xhr.send(vFiles);
              }
            }
          );
        }
      }
    }
  }, [formData["step_18"]?.right_image]);

  useEffect(() => {
    if (step === "step_18") {
      // if (moduleData === 1) {
      //   if (
      //     formData["step_18"]?.top_image &&
      //     formData.step_18?.top_image instanceof File
      //   ) {
      //     if (formData["step_18"]?.top_image.type.startsWith("image/")) {
      //       const data: any = formData["step_18"];
      //       const vFiles = data?.top_image;
      //       setLoadingSign(true);
      //       getSignUrl({ fileName: data?.top_image?.name, his: 1 }).then(
      //         (res: any) => {
      //           if (res?.success && res?.data && res?.data?.data) {
      //             setLoadingSign(false);

      //             const xhr = new XMLHttpRequest();
      //             xhr.open("PUT", res?.data?.data?.signUrls, true);
      //             xhr.setRequestHeader("Content-Type", vFiles?.type);
      //             xhr.onload = () => {
      //               dispatch(handleImageLoading(true));
      //             };
      //             xhr.onreadystatechange = () => {
      //               if (
      //                 xhr.readyState === XMLHttpRequest.DONE &&
      //                 xhr.status === 200
      //               ) {
      //                 dispatch(
      //                   handleFieldData({
      //                     step: "step_18",
      //                     value: res?.data?.data?.url,
      //                     key: "top_image",
      //                   })
      //                 );
      //               }
      //             };

      //             xhr.send(vFiles);
      //           }
      //         }
      //       );
      //     }
      //   }
      //   if (
      //     formData["step_18"]?.bottom_image &&
      //     formData.step_18?.bottom_image instanceof File
      //   ) {
      //     const data: any = formData["step_18"];
      //     const vFiles = data?.bottom_image;
      //     setLoadingSign(true);
      //     getSignUrl({ fileName: data?.bottom_image?.name, his: 1 }).then(
      //       (res: any) => {
      //         if (res?.success && res?.data && res?.data?.data) {
      //           setLoadingSign(false);

      //           const xhr = new XMLHttpRequest();
      //           xhr.open("PUT", res?.data?.data?.signUrls, true);
      //           xhr.setRequestHeader("Content-Type", vFiles?.type);
      //           xhr.onload = () => {
      //             dispatch(handleImageLoading(true));
      //           };
      //           xhr.onreadystatechange = () => {
      //             if (
      //               xhr.readyState === XMLHttpRequest.DONE &&
      //               xhr.status === 200
      //             ) {
      //               dispatch(
      //                 handleFieldData({
      //                   step: "step_18",
      //                   value: res?.data?.data?.url,
      //                   key: "bottom_image",
      //                 })
      //               );
      //             }
      //           };
      //           xhr.send(vFiles);
      //         }
      //       }
      //     );
      //   }
      //   if (
      //     formData["step_18"]?.front_image &&
      //     formData.step_18?.front_image instanceof File
      //   ) {
      //     const data: any = formData["step_18"];
      //     const vFiles = data?.front_image;
      //     setLoadingSign(true);
      //     getSignUrl({ fileName: data?.front_image?.name, his: 1 }).then(
      //       (res: any) => {
      //         if (res?.success && res?.data && res?.data?.data) {
      //           setLoadingSign(false);

      //           const xhr = new XMLHttpRequest();
      //           xhr.open("PUT", res?.data?.data?.signUrls, true);
      //           xhr.setRequestHeader("Content-Type", vFiles?.type);
      //           xhr.onload = () => {
      //             dispatch(handleImageLoading(true));
      //           };
      //           xhr.onreadystatechange = () => {
      //             if (
      //               xhr.readyState === XMLHttpRequest.DONE &&
      //               xhr.status === 200
      //             ) {
      //               dispatch(
      //                 handleFieldData({
      //                   step: "step_18",
      //                   value: res?.data?.data?.url,
      //                   key: "front_image",
      //                 })
      //               );
      //             }
      //           };
      //           xhr.send(vFiles);
      //         }
      //       }
      //     );
      //   }
      //   if (
      //     formData["step_18"]?.back_image &&
      //     formData.step_18?.back_image instanceof File
      //   ) {
      //     const data: any = formData["step_18"];
      //     const vFiles = data?.back_image;
      //     setLoadingSign(true);
      //     getSignUrl({ fileName: data?.back_image?.name, his: 1 }).then(
      //       (res: any) => {
      //         if (res?.success && res?.data && res?.data?.data) {
      //           setLoadingSign(false);

      //           const xhr = new XMLHttpRequest();
      //           xhr.open("PUT", res?.data?.data?.signUrls, true);
      //           xhr.setRequestHeader("Content-Type", vFiles?.type);
      //           xhr.onload = () => {
      //             dispatch(handleImageLoading(true));
      //           };
      //           xhr.onreadystatechange = () => {
      //             if (
      //               xhr.readyState === XMLHttpRequest.DONE &&
      //               xhr.status === 200
      //             ) {
      //               dispatch(
      //                 handleFieldData({
      //                   step: "step_18",
      //                   value: res?.data?.data?.url,
      //                   key: "back_image",
      //                 })
      //               );
      //             }
      //           };
      //           xhr.send(vFiles);
      //         }
      //       }
      //     );
      //   }
      //   if (
      //     formData["step_18"]?.left_image &&
      //     formData.step_18?.left_image instanceof File
      //   ) {
      //     const data: any = formData["step_18"];
      //     const vFiles = data?.left_image;
      //     setLoadingSign(true);
      //     getSignUrl({ fileName: data?.left_image?.name, his: 1 }).then(
      //       (res: any) => {
      //         if (res?.success && res?.data && res?.data?.data) {
      //           setLoadingSign(false);

      //           const xhr = new XMLHttpRequest();
      //           xhr.open("PUT", res?.data?.data?.signUrls, true);
      //           xhr.setRequestHeader("Content-Type", vFiles?.type);
      //           xhr.onload = () => {
      //             dispatch(handleImageLoading(true));
      //           };
      //           xhr.onreadystatechange = () => {
      //             if (
      //               xhr.readyState === XMLHttpRequest.DONE &&
      //               xhr.status === 200
      //             ) {
      //               dispatch(
      //                 handleFieldData({
      //                   step: "step_18",
      //                   value: res?.data?.data?.url,
      //                   key: "left_image",
      //                 })
      //               );
      //             }
      //           };
      //           xhr.send(vFiles);
      //         }
      //       }
      //     );
      //   }
      //   if (
      //     formData["step_18"]?.right_image &&
      //     formData.step_18?.right_image instanceof File
      //   ) {
      //     const data: any = formData["step_18"];
      //     const vFiles = data?.right_image;
      //     setLoadingSign(true);
      //     getSignUrl({ fileName: data?.right_image?.name, his: 1 }).then(
      //       (res: any) => {
      //         if (res?.success && res?.data && res?.data?.data) {
      //           setLoadingSign(false);

      //           const xhr = new XMLHttpRequest();
      //           xhr.open("PUT", res?.data?.data?.signUrls, true);
      //           xhr.setRequestHeader("Content-Type", vFiles?.type);
      //           xhr.onload = () => {
      //             dispatch(handleImageLoading(true));
      //           };
      //           xhr.onreadystatechange = () => {
      //             if (
      //               xhr.readyState === XMLHttpRequest.DONE &&
      //               xhr.status === 200
      //             ) {
      //               dispatch(
      //                 handleFieldData({
      //                   step: "step_18",
      //                   value: res?.data?.data?.url,
      //                   key: "right_image",
      //                 })
      //               );
      //             }
      //           };
      //           xhr.send(vFiles);
      //         }
      //       }
      //     );
      //   }
      // }
      if (moduleData === 4) {
        if (
          formData["step_18"]?.recipe_excel &&
          formData.step_18?.recipe_excel instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.recipe_excel;
          setLoadingSign(true);
          getSignUrl({ fileName: data?.recipe_excel?.name, his: 4 }).then(
            (res: any) => {
              if (res?.success && res?.data && res?.data?.data) {
                setLoadingSign(false);
                dispatch(
                  handleFieldData({
                    step: "step_18",
                    value: res?.data?.data?.url,
                    key: "recipe_excel",
                  })
                );
                const xhr = new XMLHttpRequest();
                xhr.open("PUT", res?.data?.data?.signUrls, true);
                xhr.setRequestHeader("Content-Type", vFiles?.type);
                xhr.send(vFiles);
              }
            }
          );
        }
      }
      if (moduleData === 5) {
        if (
          formData["step_18"]?.acir_measurement_file &&
          formData.step_18?.acir_measurement_file instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.acir_measurement_file;
          setLoadingSign(true);
          getSignUrl({
            fileName: data?.acir_measurement_file?.name,
            his: "5a",
          }).then((res: any) => {
            if (res?.success && res?.data && res?.data?.data) {
              setLoadingSign(false);
              dispatch(
                handleFieldData({
                  step: "step_18",
                  value: res?.data?.data?.url,
                  key: "acir_measurement_file",
                })
              );
              const xhr = new XMLHttpRequest();
              xhr.open("PUT", res?.data?.data?.signUrls, true);
              xhr.setRequestHeader("Content-Type", vFiles?.type);
              xhr.send(vFiles);
            }
          });
        }
        if (
          formData["step_18"]?.eis_measurement_file &&
          formData.step_18?.eis_measurement_file instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.eis_measurement_file;
          setLoadingSign(true);
          getSignUrl({
            fileName: data?.eis_measurement_file?.name,
            his: "5e",
          }).then((res: any) => {
            if (res?.success && res?.data && res?.data?.data) {
              setLoadingSign(false);
              dispatch(
                handleFieldData({
                  step: "step_18",
                  value: res?.data?.data?.url,
                  key: "eis_measurement_file",
                })
              );
              const xhr = new XMLHttpRequest();
              xhr.open("PUT", res?.data?.data?.signUrls, true);
              xhr.setRequestHeader("Content-Type", vFiles?.type);
              xhr.send(vFiles);
            }
          });
        }
      }
      if (moduleData === 6) {
        if (
          formData["step_18"]?.capacity_measurement_file &&
          formData.step_18?.capacity_measurement_file instanceof File
        ) {
          const data: any = formData["step_18"];
          const vFiles = data?.capacity_measurement_file;
          setLoadingSign(true);
          getSignUrl({
            fileName: data?.capacity_measurement_file?.name,
            his: 6,
          }).then((res: any) => {
            if (res?.success && res?.data && res?.data?.data) {
              setLoadingSign(false);
              dispatch(
                handleFieldData({
                  step: "step_18",
                  value: res?.data?.data?.url,
                  key: "capacity_measurement_file",
                })
              );
              const xhr = new XMLHttpRequest();
              xhr.open("PUT", res?.data?.data?.signUrls, true);
              xhr.setRequestHeader("Content-Type", vFiles?.type);
              xhr.send(vFiles);
            }
          });
        }
      }
    }
  }, [formData["step_18"]]);

  return (
    <React.Fragment>
      <div className="sm-stepForm-inner">
        <div className="sm-stepForm-banner">
          <SopHeader title={fieldmap[step]?.title} link={stepSop[step]} />
          <FormDetails step={step} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box">
              {step === "step_18" && header ? (
                <div className="HIS-header">{header}</div>
              ) : (
                <div className="HIS-header" style={{ color: "red" }}>
                  {headerRed}
                </div>
              )}
              <div
                className="graph_filter"
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  gap: "50px",
                  marginBottom: "20px",
                }}
              >
                {(step === "step_5" ||
                  step === "step_6" ||
                  step === "step_7" ||
                  step === "step_8" ||
                  step === "step_9" ||
                  step === "step_10") && (
                  <>
                    {" "}
                    <div className="start_end_date">
                      <h3>Start Date</h3>
                      <DatePicker
                        isClearable
                        showIcon
                        toggleCalendarOnIconClick
                        selected={moment().format("MM-DD-YYYY")}
                        // onChange={(date: any) => fons}
                        placeholderText="Start date"
                        className="startDate"
                      />
                    </div>
                    <div className="start_end_date">
                      <h3>End date</h3>
                      <DatePicker
                        showIcon
                        toggleCalendarOnIconClick
                        isClearable
                        selected={moment().format("MM-DD-YYYY")}
                        // onChange={(date: any) => setEndDate(date)}
                        placeholderText="End date"
                        className="startDate"
                      />
                    </div>
                  </>
                )}
              </div>
              {fieldmap[step] &&
                fieldmap[step]?.fields.map((field: Field, index: number) => {
                  if (field.type === "scan") {
                    return (
                      <ScanField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "field") {
                    return (
                      <InputField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "select") {
                    return (
                      <SelectField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "file") {
                    return (
                      <FileField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        loading={loadingGet}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "excel") {
                    return (
                      <ExcelUpload
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "file_read") {
                    return (
                      <FileReadField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "status") {
                    return (
                      <StatusField
                        status={index <= currentActive}
                        loading={loading}
                        loadingSign={loadingSign}
                        data={field}
                        step={step}
                        module={module}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                        onClick={handleSubmit}
                        setHeader={setHeader}
                      />
                    );
                  } else if (field.type === "custom") {
                    return (
                      <CustomAction
                        status={index <= currentActive}
                        data={field}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "reset") {
                    return (
                      <NavStep
                        step={step}
                        data={field}
                        loading={loading}
                        status={index <= currentActive}
                        key={field.key}
                        onClick={handleSubmit}
                      />
                    );
                  } else if (field.type === "HIS") {
                    return (
                      <HISInput
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        loading={loadingGet}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                        // handleClick={handleGetData}
                      />
                    );
                  } else if (field.type === "label") {
                    return (
                      <Label
                        title={field.name}
                        value={formData?.[step]?.[field.key] || ""}
                        key={field.key}
                        status={index <= currentActive}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
        {/* <div className="sm-commen-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Dessert (100g serving)</TableCell>
                  <TableCell align="right">Calories</TableCell>
                  <TableCell align="right">Fat&nbsp;(g)</TableCell>
                  <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                  <TableCell align="right">Protein&nbsp;(g)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div> */}
      </div>

      {/* <SOPModel open={open} setOpen={setOpen} /> */}
      <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
      />
    </React.Fragment>
  );
}
