import React, { useState, memo } from "react";
import { Button, FormControl, InputLabel, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import {
  handleFieldData,
  handlePackId,
} from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
// CheckCircleOutlined
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { validationHandle } from "../fieldmap";
import { QrCodeScanner } from "@mui/icons-material";
import QrCode from "./QrCode";
import { toast } from "react-toastify";

const QISInput = ({
  data,
  step,
  value,
  status,
  loading,
  setQisId,
  handleClick,
}: {
  data: Field;
  step: string;
  value: string;
  setQisId?: (value: string) => void;
  status: boolean;
  loading?: boolean;
  handleClick?: () => void;
}) => {
  const dispatch = useDispatch();
  const reset = useAppSelector((state: any) => state.inspection).toggle;
  const [fieldValue, setFieldValue] = useState<string>(value);
  const [scan, setScan] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = useState<string>("");

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [reset]);

  const handleSave = () => {
    const patternForQIS =
      /^[A-Za-z0-9]{6}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3}$/;
    if (data?.key === "QIS" && !patternForQIS?.test(fieldValue)) {
      toast.error("Pack ID must match the format XXXXXX-XXX-XXX-XXX");
      return true;
    }
    if (data?.key === "QIS") {
      dispatch(handlePackId(fieldValue));
      if (setQisId) {
        setQisId(fieldValue);
      }
    }
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: data.key,
      })
    );
    if (handleClick) {
      handleClick();
    }
  };

  function extractLastPart(url: any) {
    const urlParts = url.split("/");
    const lastPart = urlParts[urlParts.length - 1]?.trim();
    return lastPart || url;
  }
  const handleInputChange = (event: any) => {
    extractLastPart(event?.target.value);

    if (data.validate) {
      const { isError, infoDetails } = validationHandle(
        data.validate,
        extractLastPart(event.target.value),
        data
      );
      setIsError(isError);
      setFieldInfo(infoDetails);
    }
    setFieldValue(extractLastPart(event.target.value));
  };

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {step !== "step_22" && data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
        {data.validate && fieldValue && (
          <Tooltip
            className={`sm-tootip-true ${isError ? "sm-tootip-false" : ""}`}
            title={fieldInfo}
            arrow
          >
            <Button>
              {isError ? (
                <ExclamationCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )}
            </Button>
          </Tooltip>
        )}
      </InputLabel>
      <div style={{ display: "flex", gap: "5px" }}>
        <TextField
          disabled={!status}
          id={data.key}
          value={fieldValue}
          placeholder="Enter Value"
          onChange={handleInputChange}
        />
        <QrCodeScanner
          onClick={() => setScan(!scan)}
          style={{ height: "100%", marginBlock: "auto", cursor: "pointer" }}
        />
      </div>
      {!loading ? (
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={fieldValue ? false : true}
        >
          Save
        </Button>
      ) : (
        <Button variant="contained">
          <span className="report_loader"></span>Loading
        </Button>
      )}
      <QrCode open={scan} setOpen={setScan} setModuleId={setFieldValue} />
    </FormControl>
  );
};

export default memo(QISInput);
